* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Baskervville', serif;
}

.testimonials-wrapper {
	width: 100%;
}

.testimonial-description {
	margin: 1.2rem;
	text-align: center;
	justify-content: center;
	font-size: 1.8rem;
	
}

.testimonial-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
	gap: 40px;
	max-width: 1200px;
	margin: 0 auto;
	padding: 10px;
	text-align: center;
}

.testimonial-container {
	background-color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
	display: flex;
	flex-direction: column;
	height: auto;
	padding: 40px;
}

.testimonial-text {
	margin-bottom: 10px;
	color: rgb(76, 70, 70);
	font-size: 1.1rem;
	font-style: italic;
	line-height: 1.4;
}

.testimonials-header {
	display: flex;
	justify-content: center;
	margin-bottom: 0.1rem;
}

.testimonials-header h1 {
	border-bottom: 3px solid rgba(144, 68, 22, 0.8);
	padding-bottom: 0px;
	font-size: 2rem;
}

.testimonial-role {
	padding: 1px;
	font-size: 1.2rem;
	color: #666666;
	text-align: right;
	margin-right: 1rem;
}

@media screen and (max-width: 600px) {
	.testimonial-grid {
		grid-template-columns: 1fr;
	}
}
