textarea[name='message'] {
	word-wrap: break-word;
	word-break: break-all;
	height: 150px;
	resize: none;
}

.input-field-label {
	display: block;
	margin-top: 0.8rem;
	font-size: 1.1rem;;
	margin-bottom: 5px;
	margin-left: 12px;
}

input, textarea {
	width: 100%;
	font-family: 'Baskervville', serif;
	padding: 7px 12px;
}

.error-message {
	font-size: 0.9rem;
	padding: 0px;
	margin: 5px 12px;
	color: orangered;
}


