.about-image {
	float: right;
	background-image: url('/src/img/twarz.jpeg');
	background-size: cover;
	background-position: center;
	width: 400px;
	height: 500px;
	margin-left: 55px;
	margin-bottom: 30px;
}

@media (max-width: 1350px) {
	.about-image {
		width: 250px;
		height: 350px;
		margin-left: 45px;
		margin-bottom: 20px;
	}
}

@media (max-width: 1110px) {
	.about-image {
		float: none;
		margin: 0 auto;
		margin-bottom: 20px;
		margin-top: 10px;
		width: 100%;
		height: 250px;
	}
}
