.carousel {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	padding:30px;
}

.carousel-slide {
	width: 100%;
}

.slide {
	border-radius: 0.4rem;
	width: 80%;
	position: relative;
	z-index: 0;
}

.slide-hidden {
	display: none;
}

.slide-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-height: 75vh;
	max-width: 100%;
}

.arrow {
	position: absolute;
	top: 0;
	color: rgb(216, 115, 32);
	font-size: 3rem;
	z-index: 10;
	width: 50%;
	height: 100%;
	transition: color 0.6s;
}

.arrow::before,
.arrow::after {
	position: relative;
	top: 35vh;
}

.arrow:hover {
	cursor: pointer;
	color: rgb(184, 100, 32);
}

.arrow-left {
	left: 0;
}

.arrow-right {
	right: 0;
	text-align: right;
}

.carouselText {
	margin-bottom: 10px;
	text-align: center;
	line-height: 1.5;
	font-family: 'Baskervville', serif;
	color: rgb(216, 115, 32);
}

@media (max-width: 1300px) {
	.slide {
		width: 100%;
	}

	.arrow {
		font-size: 2rem;
	}
	.arrow::before,
	.arrow::after {
		top: 100%;
		transform: translateY(-2rem);
	}

	.arrow-left::before,
	.arrow-left::after {
		left: 50%;
	}

	.arrow-right::before,
	.arrow-right::after {
		right: 50%;
	}

	.carouselText {
		transform: translateY(10px);
	}

	/* .carousel {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		top: 10vh;
	} */
}

@media (max-width: 1100px) {
	.arrow-left::before,
	.arrow-left::after {
		left: 0;
	}

	.arrow-right::before,
	.arrow-right::after {
		right: 0;
	}

	/* img.slide {
		min-width: 300px;
		max-width: 400px;
	}

	.carousel {
		flex-direction: column;
	}

	.arrows-container {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: 10px;
	}

	.arrow {
		width: 2rem;
		height: 2rem;
		font-size: 2rem;
		position: static;
		margin: 10px;
	}

	.arrow-left {
		left: 0;
	}

	.arrow-right {
		right: 0;
	}

	.carouselText {
		margin-top: 10px;
		text-align: center;
		line-height: 1.1;
		font-family: Lato;
	} */
}

@media (max-width: 700px) {
	/* .carousel {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		height: 250px;
		width: 250px;
		z-index: 1;
	}

	.slide {
		width: 100%;
		height: auto;
		max-height: 90%;
		position: relative;
	}

	.arrow {
		width: 2rem;
		height: 2rem;
		font-size: 2rem;
	}

	.arrow-left {
		left: 0.2rem;
	}

	.arrow-right {
		right: 0.2rem;
	}

	.carouselText {
		margin-top: 10px;
		text-align: center;
		line-height: 1.1;
		font-family: Lato;
		color: rgb(34, 63, 34);
	} */
}

@keyframes fadeIn {
	0% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
	}
}

.anim {
	opacity: 0.6;
	animation: fadeIn 0.9s ease forwards;
}
