.page-content-wrapper {
	margin: 90px 40px 80px;
	flex: 1;
	display: flex;
}

.page-content {
	flex: 1;
	font-family: 'Baskervville', serif;
	border-top: 4px solid rgba(144, 68, 22, 0.8);
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
	padding: 30px 60px 40px;
}

.content-header {
	font-size: 2rem;
	margin-bottom: 20px;
	text-align: center;
}

.content-paragraph {
	margin-bottom: 25px;
	font-size: 1.1rem;
	text-align: justify;
	color: rgb(76, 70, 70);
}

@media (min-width: 1200px) {
	.content-header {
		font-size: 2.5rem;
		margin-bottom: 30px;
	}

	.content-paragraph {
		font-size: 1.3rem;
	}
}

@media (min-width: 768px) {
	.page-content-wrapper {
		margin-left: 400px;
		margin-top: 40px;
	}
	.page-content {
		border-top: none;
		border-left: 4px solid rgba(144, 68, 22, 0.8);
		box-shadow: none;
	}

	.content-header {
		text-align: left;
	}
}



@media (max-width: 768px) {
	.page-content-wrapper {
		margin:90px 0px 0px -5px;
		
	}

}

