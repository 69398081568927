.footer-wrapper {
    margin-bottom: 50px;
}

.footer {
	background-color:rgb(162,102,65);
	height: 40px;
	width: 100%;
	z-index: 100;
	position: fixed;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	
}

.footer-text {
	color: white;
    font-size: 0.4rem;
    margin: 0;
    padding: 5px;
	text-transform: uppercase;
	font-family: 'Roboto';
	font-weight: 200;
}


