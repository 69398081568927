.burger-wrapper {
	display: none;
}

nav.main-nav {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 360px;
	height: 100%;
	box-shadow: rgba(17, 12, 46, 0.1) 0px 48px 100px 0px;
}

.main-menu {
	background-color: rgb(138, 154, 91);
	width: 100%;
	min-height: 100vh;
}

.main-menu-item {
	list-style: none;
	font-family: 'Montserrat', sans-serif;
	margin-left: 0px;
}

.main-menu-item:first-child .main-menu-item-link {
	margin-top: 10%;
}

.main-menu-item:last-child .main-menu-item-link {
	margin-bottom: 60px;
}

.main-menu-item-link {
	display: block;
	text-decoration: none;
	color: rgb(34, 63, 34);
	padding: 0.4rem;
	margin: 0 0.9rem;
	font-size: 1.8rem;
	text-transform: uppercase;
	font-family: 'Roboto', sans-serif;
	font-weight: 100;
}

.main-menu-item-link.active,
.main-menu-item-link:hover {
	color: burlywood;
}

@media (max-width: 768px) {
	.burger-wrapper {
		display: block;
		background-color: rgb(237, 236, 232);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		padding: 8px 15px;
		box-shadow: rgba(17, 12, 46, 0.1) 0px 48px 100px 0px;
	}

	button.burger {
		cursor: pointer;
		padding: 20px;
		margin-left: 10px;
		border: none;
		background: none;
	}

	.burger-menu {
		position: sticky;
		margin-bottom: 5px;
		display: block;
		height: 8px;
		width: 50px;
		background-color: rgb(138, 154, 91);
	}

	.burger-menu:last-child {
		margin-bottom: 0;
	}

	nav.main-nav {
		box-shadow: none;
		position: fixed;
		top: 0;
		left: 0;
		height: 70px;
		width: 0%;
		z-index: 20;
	}

	.main-menu {
		display: none;
	}

	.main-menu.open {
		display: block;
		background-color: rgb(138, 154, 91);
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		z-index: 1999;
	}

	.main-menu-item-link {
		font-size: 2rem;
	}

	.main-menu-item:first-child .main-menu-item-link {
		margin-top: 10px;
	}

	.main-menu-item:last-child .main-menu-item-link {
		margin-bottom: 10px;
	}

	.main-menu.open .main-menu-item-link {
		font-size: 2.3rem;
		justify-content: center;
	}
}
