.contact-page {
	flex-direction: column;
}


.contact-header {
	font-size: 2rem;
	margin-bottom: 40px;
	display: inline-block;
	border-bottom: 3px solid rgba(144, 68, 22, 0.8);
}

.contact-layout {
	display: flex;
	font-size: 2.5rem;
	gap: 40px;
}

.left-col,
.right-col {
	width: 50%;
}

@media (max-width: 1200px)  {
	.contact-page {
		align-items: center;
        justify-content: center;
        display: flex;
		padding: 20px 60px;
	}
	
	.contact-layout {
		flex-direction: column;
	}

	.left-col,
	.right-col {
		width: 100%;
	}	
}

.contact-box {
	background-color: white;
	padding: 40px;
	margin-bottom: 40px;
}

.contact-details-subheader {
	text-align: center;
	font-size: 1.5rem;
	margin-bottom: 20px;
}

.contact-details-row-header {
	text-align: center;
	font-size: 1.1rem;
	width: 100px;
	display: inline-block;
	font-weight: bold;
}

.contact-description {
	line-height: 1.5;
	color: #333;
	font-size: 1.1rem;
	text-align: center;
}

.contact-description:first-child {
	padding-bottom: 20px;
}

.contact-form-container {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	width: 100%;
	padding: 0px;
	color: rgb(76, 70, 70);
	flex-direction: column;
	color: rgb(76, 70, 70);
	margin: 5px 60px 0px 60px;
}

.button-submit {
	width: 40%;
	display: block;
	margin: 20px auto 0;
	border: transparent;
	background-color: rgb(162, 102, 65);
	color: #fff;
	cursor: pointer;
	font-family: 'Baskervville', serif;
	font-size: 1.2rem;
	padding: 0.4rem;
	border-radius: 10px;

}

.form-submitted-message {
	color: red;
	padding: 10px;
	border-radius: 5px;
	text-align: center;
	margin-top: 10px;
}

.contact-link {
	line-height: 1.5;
	color: #333;
	font-size: 1.1rem;
	text-decoration: underline;
}
