* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-size: 20px;
}

body {
	background-color: rgb(237, 236, 232);
}
