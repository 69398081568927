.dropdown-menu li:first-child a {
	margin-top: 0;
	animation: fadeIn 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.dropdown-menu li:last-child a {
	margin-bottom: 0;
	animation: fadeIn 0.5s 0.1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.dropdown-menu-item-link {
	display: block;
	text-decoration: none;
	color: rgb(34, 63, 34);
	padding: 0.4rem;
	margin: 0 0.9rem;
	font-size: 1.4rem;
	text-transform: uppercase;
	font-family: 'Roboto', sans-serif;
	font-weight: 100;
}

.dropdown-menu-item-link.active,
.dropdown-menu-item-link:hover {
	color: burlywood;
}

@media (max-width: 800px) {
	.dropdown-menu-item-link {
		font-size: 1.7rem;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
