.flexContainer {
	display: flex;
	margin: 1rem;
	margin-top: 0px;
}

.textPiano {
	flex: 1;
	margin: 1.5rem;
	font-family: 'Baskervville', serif;
	border-left: 4px solid rgba(144, 68, 22, 0.8);
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.pianoImage {
	flex: 1;
	float: right;
	background-image: url('/public/therapy_small.jpg');
	background-size: cover;
	height: 400px;
	width: 40%;
	margin-right: 2rem;
	margin-left: 2rem;
	margin-top: 1.5rem;
	margin-bottom: 2rem;
}

.pianoHeader {
	font-size: 2.5rem;
	margin-left: 5%;
	margin-top: 2%;
}

.pianoText {
	font-size: 1.3rem;
	text-align: justify;
	color: rgb(76, 70, 70);
	margin-left: 2%;
	flex: 1;
}

.pianoList {
	color: rgb(76, 70, 70);
	margin-left: 2.5rem;
	margin-bottom: 2rem;
}

.pianoList li {
	list-style-type: disc;
	font-size: 1.2rem;
}


.pianoPricingHeading {
	font-size: 1.3rem;
	color: rgb(76, 70, 70);
}

@media (max-width: 680px) {
	.pianoList li {
		font-size: 1.2rem;
		margin-left: 1px;
	}

	.pianoImage {
		float:none;
		width: 200px;
		height: 150px;
	}
}
